import { FETCH_PRICE_TYPES } from '../constants/actions';
import { dispatch } from '@store/store';
import Request from './utils/Request';
import { adminService } from './adminService';
import { fetch } from '@apis/ServiceController';
import { apiConstant } from '@apis/constant';
import { tokenUtil } from '@utils/token';

const getPriceTypes = async (body) => {
   return await dispatch(
      Request.post(FETCH_PRICE_TYPES, {
         data: body,
      })
   );
};

const createPriceType = async (body) => {
   return fetch({
      method: 'post',
      url: apiConstant.CREATE_PRICE_TYPE,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      errorHandler: adminService.handleError,
   });
};

const deletePriceType = async (uid) => {
   return fetch({
      method: 'delete',
      url: apiConstant.DELETE_PRICE_TYPE,
      params: {
         uid,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      errorHandler: adminService.handleError,
   });
};

const updatePriceType = async (uid, body) => {
   return fetch({
      method: 'put',
      url: apiConstant.UPDATE_PRICE_TYPE,
      params: {
         uid,
      },
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      errorHandler: adminService.handleError,
   });
};

const getPriceTypeMatrix = async (body) => {
   return fetch({
      method: 'post',
      url: apiConstant.GET_PRICE_TYPE_MATRIX,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      errorHandler: adminService.handleError,
   });
};

export const priceTypeService = {
   getPriceTypes,
   createPriceType,
   updatePriceType,
   deletePriceType,
   getPriceTypeMatrix,
};

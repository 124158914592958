import React, { memo, useCallback, useEffect, useState } from 'react';
import { Tooltip, Form } from 'antd';
import EditingDrawer from '../components/EditingDrawer';
import RequestModel from '../../apis/models/RequestModel';
import { message } from '../../components/Common/Message';
import _forIn from 'lodash/forIn';
import { InputType } from '@utils/enums/InputType';
import { priceTypeService } from '@services/priceTypeService';

const formDefinitions = [
   {
      name: 'name',
      label: 'Name',
      placeholder: 'Wholesale type name',
      rules: [
         {
            required: true,
            message: 'Please input your Wholesale type name.',
         },
      ],
      isRequired: true,
   },
   {
      name: 'description',
      label: 'Description',
      type: InputType.TEXTAREA,
      placeholder: 'Description',
   },
   {
      name: 'from_quantity',
      label: 'From',
      type: InputType.NUMBER,
      placeholder: 'From quantity',
   },
   {
      name: 'to_quantity',
      label: 'To',
      type: InputType.NUMBER,
      placeholder: 'To quantity',
   },
];

const BtnEdit = memo(({ rowData, onSuccess, tooltip, ...ownProps }) => {
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const [form] = Form.useForm();
   const [submitting, setSubmitting] = useState(false);

   const showDrawer = useCallback(() => {
      if (rowData) {
         const valueObj = {};
         _forIn(rowData, (value, key) => {
            valueObj[key] = value;
         });
         form.setFieldsValue(valueObj);
      }
      setIsDrawerOpen(true);
   }, [form, rowData]);

   const onClose = useCallback(() => {
      setIsDrawerOpen(false);
   }, []);

   useEffect(
      () => {
         if (rowData) {
            form.setFieldsValue({
               name: rowData?.name || '',
               description: rowData?.description || '',
               from_quantity: rowData?.from_quantity ?? '',
               to_quantity: rowData?.to_quantity ?? '',
            });
         }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [rowData]
   );

   const onSubmit = useCallback(
      async (values) => {
         await setSubmitting(true);
         const body = {
            name: values?.name || '',
            description: values?.description || '',
            from_quantity: values?.from_quantity ?? '',
            to_quantity: values?.to_quantity ?? '',
         };
         const requestBody = new RequestModel(body);
         let responseData;
         // is update
         if (rowData?.uid) {
            responseData = await priceTypeService.updatePriceType(rowData.uid, requestBody);
         }
         // is create
         else {
            responseData = await priceTypeService.createPriceType(requestBody);
         }
         if (responseData?.isSuccess) {
            onSuccess();
            message.success(
               rowData ? `You successfully updated wholesale type.` : `Create new wholsale type successfully.`
            );
            onClose();
            form.resetFields();
         } else {
            message.error(responseData.message);
         }

         setSubmitting(false);
      },
      [onSuccess, rowData, form, onClose]
   );

   const tooltipProps = !tooltip ? { visible: false } : {};

   return (
      <>
         <Tooltip {...tooltipProps} placement="bottom" title={tooltip || ''} color="black">
            <button className={ownProps.className} onClick={showDrawer}>
               {ownProps.children}
            </button>
         </Tooltip>
         <EditingDrawer
            title={rowData ? 'Edit Wholesale Type' : 'Create a new Wholesale Type'}
            isOpen={isDrawerOpen}
            form={form}
            formDefinitions={formDefinitions}
            submitting={submitting}
            currentUid={rowData?.uid}
            onClose={onClose}
            onSubmit={onSubmit}
         />
      </>
   );
});

export default BtnEdit;
